import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66')
];

export const server_loads = [0,2,13,14];

export const dictionary = {
		"/": [17],
		"/account/integrations": [18,[2]],
		"/account/invoices": [19,[2]],
		"/account/preferences": [20,[2]],
		"/account/profile": [21,[2]],
		"/account/subscription": [22,[2]],
		"/app": [23,[3]],
		"/checkout": [24],
		"/checkout/update-payment": [25],
		"/community/documents/[id]": [26,[4]],
		"/community/notes/[id]": [27,[4]],
		"/configure-integration/bp/setup": [28],
		"/consult": [29],
		"/create-account": [30,[5]],
		"/create-account/details": [31,[5]],
		"/create-account/password": [32,[5]],
		"/customisation/direct-dictate": [~33,[6]],
		"/customisation/document-personalisation": [34,[6]],
		"/customisation/documents": [35,[6,7]],
		"/customisation/documents/new_document": [37,[6,7]],
		"/customisation/documents/[slug]": [36,[6,7]],
		"/customisation/find-and-replace": [38,[6]],
		"/customisation/general": [39,[6]],
		"/customisation/macros": [40,[6]],
		"/customisation/note-personalisation": [41,[6]],
		"/customisation/patient-forms": [42,[6]],
		"/demo": [43,[8]],
		"/dictate": [44,[9]],
		"/downtime": [~45],
		"/form/[code]": [46],
		"/help": [47,[10]],
		"/history": [48,[11]],
		"/history/documents/[slug]": [~49,[11,12]],
		"/logging-in": [50],
		"/login": [51],
		"/login/mfa": [52],
		"/organisation/manage": [53,[13]],
		"/organisation/preferences": [54,[13]],
		"/organisation/subscription": [55,[13]],
		"/organisation/usage": [56,[13]],
		"/personalisation/documents/legacy/[slug]": [58],
		"/personalisation/documents/[slug]": [57],
		"/personalisation/notes/[slug]": [59],
		"/preconsult/[code]": [60],
		"/security/mfa": [61,[14]],
		"/signin": [62,[15]],
		"/signin/mfa": [63,[15]],
		"/signin/password": [64,[15]],
		"/signup": [65],
		"/update-password": [66,[16]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';